import { Label } from 'reactstrap';

const PreLoadInfo = (props) => {
  const { dataContent } = props;

  return (
    <>
      {dataContent ? (
        <section key={`key-${dataContent.NombreCompleto}`}>
          <div className="separated__info">
            <div
              className="content__info"
              key={`key--${dataContent.NombreCompleto}--`}
            >
              <Label className="person person-title">Nombre Completo</Label>
              <div id="NombreCompleto" className="person person-data">
                {dataContent.NombreCompleto}
              </div>
            </div>
            <div className="content__info" key={`key--${dataContent.id}--`}>
              <Label className="person person-title">
                Número de Identificación
              </Label>
              <div id="id" className="person person-data">
                {dataContent.id}
              </div>
            </div>
            <div
              className="content__info"
              key={`key--${dataContent.CargoDesempenado}--`}
            >
              <Label className="person person-title">Cargo desempeñado</Label>
              <div id="CargoDesempenado" className="person person-data">
                {dataContent.CargoDesempenado}
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default PreLoadInfo;
