import React, { useContext } from 'react';
import PreLoadInfo from '../components/preloadform/index';
import ListItemsContex from '../context/ListItemsContex';
import TitleSections from '../components/titles';
import TypeQuestions from './TypeQuestion';
import ModalAlert from '../components/modalalert';
import Loading from '../components/loading';
import QuestionsContent from '../assets/json/modalContent.json';
import FreeComment from '../components/freecomment';
export const AllQuestions = () => {
  const {
    listItems,
    listSections,
    loadQuestions,
    errorLoad,
    preload,
  }: any = useContext(ListItemsContex);
  return (
    <>
      {loadQuestions ? (
        <Loading />
      ) : errorLoad ? (
        <ModalAlert modalContent={QuestionsContent.ModalErrorEncuestaGeneric} />
      ) : (
        <>
          <TitleSections id={`section9`} title={'Información General'} />
          <PreLoadInfo dataContent={preload} />
          {listItems &&
            listItems.value &&
            listItems.value.map((question: any) =>
              question.fields.TipoPregunta === 'SeleccionMultipleDosNiveles' ?
                (question.fields.Key === 'MotivosRenuncia' && preload?.Destinatario) !== 'Proveedor' ?
                  null :
                  (<TypeQuestions dataContent={question.fields} />) : null
            )}
          {listSections &&
            listSections.value &&
            listSections.value.map((sections: any) => (
              <>
                {sections.fields.Title !== 'Información General' ? (
                  <>
                    <TitleSections
                      id={`section${sections.fields.id}`}
                      title={sections.fields.Title}
                    />
                    {listItems &&
                      listItems.value &&
                      listItems.value.map((question: any) =>
                        sections.fields.id ===
                          question.fields.SeccionLookupId ? (
                          <TypeQuestions dataContent={question.fields} />
                        ) : null
                      )}
                  </>
                ) : null}
              </>
            ))}
        </>
      )}
      {preload?.Destinatario === 'Proveedor'
        ? listItems &&
        listItems.value &&
        listItems.value.map((question: any) =>
          question.fields.Title === 'Análisis de entrevista' ? (
            <>
              <FreeComment dataComment={question.fields} />
            </>
          ) : null
        )
        : listItems &&
        listItems.value &&
        listItems.value.map((question: any) =>
          question.fields.Title === 'Comentarios generales' ? (
            <>
              <FreeComment dataComment={question.fields} />
            </>
          ) : null
        )}
    </>
  );
};
