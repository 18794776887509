import React from 'react';
import EncuestaRetiro from './pages/EncuestaRetiro';
import './App.scss';

const App = () => {
  return (
    <EncuestaRetiro/>   
  );
}

export default App;
