import { useState, useContext, useEffect } from 'react';
import Rate from 'rc-rate';
import ListItemsContex from '../../context/ListItemsContex';
import { Label, FormGroup, Input, Tooltip } from 'reactstrap';
import 'rc-rate/assets/index.css';

const TableRateQuestion = ({ dataContent }) => {
  const {
    sendEncuesta,
    setSendEncuesta,
    fieldsReq,
    handleChangeInput,
  } = useContext(ListItemsContex);

  const { setAnswers } = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    dataContent.PreguntasCalificacion_x003A_Key.map((x) => {
      x.checked = false;
    });
  }, []);

  const handleInput = (e, nameAnswer, y) => {
    dataContent.PreguntasCalificacion_x003A_Key[y].checked = false;
    const object = { ...sendEncuesta };
    object[nameAnswer] = e;
    setSendEncuesta(object, setAnswers);
    setSendEncuesta({ ...sendEncuesta, ...object });
  };

  const handleRadio = (e, nameAnswer, y) => {
    dataContent.PreguntasCalificacion_x003A_Key[y].checked = true;
    const object = { ...sendEncuesta };
    object[nameAnswer] = e.target.value;
    setSendEncuesta(object, setAnswers);
    setSendEncuesta({ ...sendEncuesta, ...object });
  };
  const getAlert = () => {
    let keys = dataContent.PreguntasCalificacion_x003A_Key.map(
      (element) => element.LookupValue
    );
    let response = keys.some((key) => !sendEncuesta[key]);

    return response;
  };
  return (
    <>
      <section
        className="section-default separated required"
        key={dataContent.id}
      >
        <em className="fas fa-info-circle" id={`Tooltip${dataContent.id}`} />
        {dataContent.Tooltip ? (
          <Tooltip
            placement="top-start"
            isOpen={tooltipOpen}
            target={`Tooltip${dataContent.id}`}
            toggle={toggle}
          >
            {dataContent.Tooltip}
          </Tooltip>
        ) : null}
        <Label className="separated-title">{dataContent.Title}</Label>
        <div className="rate-container rate-list">
          <div className="rate-container-column">
            <ul>
              <li className="list__title">Item</li>
              {dataContent.PreguntasCalificacion.map(
                (qualifQuest, keyqualifQuest) => (
                  <li
                    id={qualifQuest.LookUpValue}
                    key={`${qualifQuest.LookUpValue}--${keyqualifQuest}`}
                  >
                    {qualifQuest.LookupValue}
                  </li>
                )
              )}
              {dataContent.PreguntasCalificacion_x003A_Key.map(
                (element) => element.LookupValue
              ).some((anyKey) =>
                Object.values(fieldsReq).includes(anyKey)
              ) &&
                getAlert() && (
                  <p className="alert_required">
                    <em className="fas fa-times-circle" />
                    Faltan campos por diligenciar
                  </p>
                )}
            </ul>
          </div>
          <div className="rate-container-separated"></div>
          <div className="rate-container-column">
            <ul className="starts-column">
              <li className="list__title"></li>
              {dataContent.PreguntasCalificacion_x003A_Key.map(
                (qualifQuest, keyqualifQuest) => (
                  <li
                    className={`${qualifQuest.LookupValue}`}
                    key={`${qualifQuest.LookUpId}-${keyqualifQuest}`}
                  >
                    <Label id={`label-rate-${keyqualifQuest}`}>
                      <Rate
                        key={`rate-${keyqualifQuest}`}
                        onChange={(e) =>
                          handleInput(
                            e,
                            qualifQuest.LookupValue,
                            keyqualifQuest
                          )
                        }
                        value={sendEncuesta[qualifQuest.LookupValue]}
                        character={<em className="fas fa-star" />}
                      />
                    </Label>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="rate-container-column" key={`-${dataContent.Key}--`}>
            <ul>
              <li className="list__title"></li>
              {dataContent.RespuestasCalificacion.map((x, y) => (
                <>
                  {dataContent.PreguntasCalificacion_x003A_Key.map(
                    (qualifQuest, keyqualifQuest) => (
                      <li key={`${keyqualifQuest}-${qualifQuest.LookUpId}`}>
                        <Label check>
                          <Input
                            onChange={(e) =>
                              handleRadio(
                                e,
                                qualifQuest.LookupValue,
                                keyqualifQuest
                              )
                            }
                            checked={qualifQuest.checked}
                            value={'N/A'}
                            name={qualifQuest.LookupValue}
                            id={dataContent.Key}
                            data-testid={dataContent.Key}
                            type="radio"
                          />
                          <div className="control" />
                          {x.LookupValue}
                        </Label>
                      </li>
                    )
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
        {dataContent.Comentario0 ? (
          <FormGroup check>
            <Label check className="separated-title">
              Comentarios
              <Input
                onChange={handleChangeInput}
                type="textarea"
                value={sendEncuesta[dataContent.Comentario0]}
                name={`Comentario${dataContent.Key}`}
              />
            </Label>
          </FormGroup>
        ) : null}
      </section>
    </>
  );
};

export default TableRateQuestion;
