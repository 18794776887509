import { Container } from 'reactstrap';
import IntroductionText from '../introduction';
import { AllQuestions } from '../../utils/questions';
import { ShowModal } from '../../utils/showModal';
import './index.scss';
import { useContext } from 'react';
import ListItemsContext from '../../context/ListItemsContex';
const QuestionsTemplate = () => {
  const { disabledSend } = useContext(ListItemsContext);

  return (
    <>
      <Container className="nav-steps">
        <IntroductionText />
        <ShowModal />
        {!disabledSend && <AllQuestions />}
      </Container>
    </>
  );
};

export default QuestionsTemplate;
