import { getCachedToken, getGetHttpOptions } from '../utils/utils';
import { ConfigService } from './config';

export default class ListItemsService {
  static urlServiceItems = ConfigService.urlBaseServiceItems;
  static urlServiceSections = ConfigService.urlBaseServiceSections;
  static urlSendEncuesta = ConfigService.urlBaseServiceSendEncuesta;
  static urlValidExist = ConfigService.urlBaseServicevalidExist;
  static urlGetEncuesta = ConfigService.urlBaseGetEncuesta;

  static getListItems = async () => {
    try {
      const res = await fetch(ListItemsService.urlServiceItems, getGetHttpOptions());
      const data = await res.json();
      sessionStorage.setItem('sessionListItems', JSON.stringify(data));
      return data;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  };

  static getListSections = async () => {
    try {
      const res = await fetch(ListItemsService.urlServiceSections, getGetHttpOptions());
      const data = await res.json();
      return data;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  };

  static sendEncuesta = async (form: any) => {
    try {
      const res = await fetch(ListItemsService.urlSendEncuesta, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCachedToken()}`
        },
        body: JSON.stringify(form),
      });
      const data = await res.json();

      return data;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  };

  static validExist = async (CodigoNomina: any) => {
    try {
      const res = await fetch(ListItemsService.urlValidExist, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCachedToken()}`
        },
        body: JSON.stringify(CodigoNomina),
      });
      const data = await res.json();
      return data;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  };

  static getEncuesta = async (CodigoNomina: any) => {
    try {
      const res = await fetch(ListItemsService.urlGetEncuesta, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCachedToken()}`
        },
        body: JSON.stringify(CodigoNomina),
      });
      const data = await res.json();
      return data;
    } catch (error: any) {
      throw new Error(error?.message);
    }
  };
}
