import './index.scss';

const Loading = () => {

    return (
        <div className="container-loading">
            <div className="loader"></div>
            <p>Cargando...</p>
        </div>
    )
};

export default Loading;