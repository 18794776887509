import React, { useState, useContext } from 'react';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';
import { Label, Tooltip, FormGroup, Input } from 'reactstrap';
import ListItemsContex from '../../context/ListItemsContex';
const RateQuestion = ({ dataContent }) => {
  const {
    sendEncuesta,
    setSendEncuesta,
    fieldsReq,
    handleChangeInput,
  } = useContext(ListItemsContex);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleInput = (e) => {
    const object = { ...sendEncuesta };
    object[dataContent.Key] = e;
    setSendEncuesta(object);
  };
  return (
    <>
      {
        <section
          className={`section-default separated ${dataContent.Requerido ? 'required' : ''
            }`}
          key={dataContent.key}
        >
          <em className="fas fa-info-circle" id={`Tooltip${dataContent.id}`} />
          {dataContent.Tooltip ? (
            <Tooltip
              placement="top-start"
              isOpen={tooltipOpen}
              target={`Tooltip${dataContent.id}`}
              toggle={toggle}
            >
              {dataContent.Tooltip}
            </Tooltip>
          ) : null}

          <Label className="separated-title">{dataContent.Title}</Label>
          <div
            className="rate-container "
            key={`${dataContent.Key}-${dataContent.Id}`}
          >
            <div className={`single-rate ${dataContent.Key}`}>
              <Rate
                datatest-id="rate"
                onChange={handleInput}
                defaultValue={0}
                value={
                  sendEncuesta[dataContent.Key]
                    ? sendEncuesta[dataContent.Key]
                    : 0
                }
                character={<em className="fas fa-star" />}
              />
            </div>
          </div>
          {Object.values(fieldsReq).map((field, key) =>
            field === dataContent.Key && !sendEncuesta[dataContent.Key] ? (
              <p className="alert_required">
                <em className="fas fa-times-circle" />
                Campo obligatorio
              </p>
            ) : (
              ''
            )
          )}
          {dataContent.Comentario0 ? (
            <FormGroup check>
              <Label check className="separated-title">
                Comentarios
                <Input
                  type="textarea"
                  onChange={handleChangeInput}
                  value={sendEncuesta[dataContent.Comentario0]}
                  name={`Comentario${dataContent.Key}`}
                />
              </Label>
            </FormGroup>
          ) : null}
        </section>
      }
    </>
  );
};

export default RateQuestion;
