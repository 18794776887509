import React from 'react';
import Header from '../components/header/index';
import Footer from '../components/footer/index';
import QuestionsTemplate from '../components/questionstemplate';
import { ListItemsProvider } from '../context/ListItemsContex';
import { UseListItems } from '../hooks/useList';
export const app = () => {
  const {
    listItems,
    setListItems,
    listSections,
    setLisSections,
    loadQuestions,
    setloadQuestions,
    loadSections,
    setloadSections,
    sendEncuesta,
    setSendEncuesta,
    handleChangeInput,
    preload,
    sendAllEncuesta,
    setPreload,
    setValueStart,
    disabledSend,
    setDisabledSend,
    valueStart,
    showModal,
    setShowModal,
    showModalError,
    setShowModalError,
    showModalErrorAuth,
    setShowModalErrorAuth,
    showModalErrorGeneric,
    setShowModalErrorGeneric,
    dataValidate,
    setDataValidate,
    errorLoad,
    fieldsReq,
    showLoad,
    setShowLoad,
    setFieldsReq,
    errorLoadList,
    showModalExist,
    setShowModalExist,
    valueChecked,
    setValueChecked,
    showModalExistEnc,
    setShowModalExistEnc,
    loadingBoss,
    setLoadingBoss,
  } = UseListItems();

  return (
      <ListItemsProvider
        value={{
          listItems,
          setListItems,
          listSections,
          loadQuestions,
          setloadQuestions,
          setLisSections,
          loadSections,
          setloadSections,
          sendEncuesta,
          setSendEncuesta,
          handleChangeInput,
          sendAllEncuesta,
          preload,
          setPreload,
          setValueStart,
          valueStart,
          disabledSend,
          setDisabledSend,
          showModal,
          showModalError,
          setShowModalError,
          setShowModal,
          showModalErrorAuth,
          setShowModalErrorAuth,
          showModalErrorGeneric,
          setShowModalErrorGeneric,
          dataValidate,
          setDataValidate,
          fieldsReq,
          setFieldsReq,
          showLoad,
          setShowLoad,
          errorLoad,
          errorLoadList,
          showModalExist,
          setShowModalExist,
          valueChecked,
          setValueChecked,
          showModalExistEnc,
          setShowModalExistEnc,
          loadingBoss,
          setLoadingBoss,
        }}
      >
        <div className="content-app">
          <div className="body-containerFlex">
            <Header />
            <main className="main-ppal">
              <QuestionsTemplate />
            </main>
            <Footer />
          </div>
        </div>
      </ListItemsProvider>
  );
};

export default app;
