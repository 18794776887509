import { Label, Form, Col, FormGroup, Input } from 'reactstrap';
import ListItemsContex from '../../context/ListItemsContex';
import React, { useContext } from 'react';

const TableCheckQuestion = ({ tableContent }) => {
  const { sendEncuesta, handleChangeInput, fieldsReq } = useContext(
    ListItemsContex
  );

  return (
    <section className="section-default">
      <Form>
        <Col xs="12" sm="12">
          <div
            className={`separated ${tableContent.Requerido ? 'required' : ''}`}
            key={`${tableContent.Key}`}
          >
            <Label>{tableContent.Title}</Label>
            <div className="table__list">
              <FormGroup>
                <div className="table__list-column">
                  {tableContent.RespuestaRetiro &&
                    tableContent.RespuestaRetiro.length > 0 &&
                    tableContent.RespuestaRetiro.map(
                      (res, keyqualifQuest) => (
                        <li>
                          <div className="checkOptions">
                            <FormGroup
                              className={`${tableContent.Key}`}
                              check
                              key={`${tableContent.Key}`}
                            >
                              <Label check>
                                <Input
                                  type="checkbox"
                                  onChange={handleChangeInput}
                                  value={res.LookupValue}
                                  name={tableContent.Key}
                                />
                                {res.LookupValue}
                                <div className="control" />
                              </Label>
                            </FormGroup>
                          </div>
                          {sendEncuesta['MotivosRenuncia']?.indexOf(
                            res.LookupValue
                          ) >= 0 &&
                            tableContent.RespuestaRetiro[keyqualifQuest]
                              .respuesta && tableContent.RespuestaRetiro[keyqualifQuest]
                                .respuesta.fields ? (
                            <ul className="second-options-check">
                              {tableContent.RespuestaRetiro[
                                keyqualifQuest
                              ].respuesta.fields.RespuestasRetiroNivel2.map(
                                (rs, index) => (
                                  <li>
                                    <div className="checkOptions">
                                      <FormGroup
                                        check
                                        key={`${rs.LookupValue}`}
                                      >
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            onChange={handleChangeInput}
                                            value={rs.LookupValue}
                                            name={tableContent.Key}
                                          />
                                          {rs.LookupValue}
                                          <div className="control" />
                                        </Label>
                                      </FormGroup>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : null}
                        </li>
                      )
                    )}
                </div>
              </FormGroup>
            </div>
            {Object.values(fieldsReq).map((field, key) =>
              field === tableContent.Key && !sendEncuesta[tableContent.Key] ? (
                <p className="alert_required">
                  <em className="fas fa-times-circle" />
                  Campo obligatorio
                </p>
              ) : (
                ''
              )
            )}
          </div>
        </Col>
      </Form>
      {tableContent.Comentario0 ? (
        <FormGroup check>
          <Label check className="separated-title">
            Comentarios
            <Input
              onChange={handleChangeInput}
              type="textarea"
              value={sendEncuesta[tableContent.Comentario0]}
              name={`Comentario${tableContent.Key}`}
            />
          </Label>
        </FormGroup>
      ) : null}
    </section>
  );
};

export default TableCheckQuestion;
