import React, { useContext } from 'react';
import ListItemsContex from '../../context/ListItemsContex';
import { Label, Form, Col, FormGroup, Input } from 'reactstrap';
const RadioQuestion = ({ dataContent }) => {
  const { sendEncuesta, handleChangeInput, fieldsReq } = useContext(
    ListItemsContex
  );
  return (
    <>
      <section className="section-default">
        <Form>
          <Col xs="12" sm="12">
            <FormGroup>
              <div
                className={`separated ${dataContent.Requerido ? 'required' : ''
                  }`}
                key={`${dataContent.Key}`}
              >
                <Label>{dataContent.Title}</Label>
                <div className="radioOptions">
                  <>
                    {dataContent.RespuestasPregunta &&
                      dataContent.RespuestasPregunta.length > 0 &&
                      dataContent.RespuestasPregunta.map(
                        (res, keyqualifQuest) => (
                          <>
                            <FormGroup
                              className={`${dataContent.Key}`}
                              check
                              key={`${dataContent.Key}`}
                            >
                              <Label check>
                                <Input
                                  type="radio"
                                  onChange={handleChangeInput}
                                  value={res.LookupValue}
                                  name={dataContent.Key}
                                />
                                {res.LookupValue}
                                <div className="control" />
                              </Label>
                            </FormGroup>
                          </>
                        )
                      )}
                  </>
                </div>
                {Object.values(fieldsReq).map((field, key) =>
                  field === dataContent.Key &&
                    !sendEncuesta[dataContent.Key] ? (
                    <p className="alert_required">
                      <em className="fas fa-times-circle" />
                      Campo obligatorio
                    </p>
                  ) : (
                    ''
                  )
                )}
              </div>
            </FormGroup>
          </Col>
        </Form>
        {dataContent.Comentario0 ? (
          <FormGroup check>
            <Label check className="separated-title">
              Comentarios
              <Input
                onChange={handleChangeInput}
                type="textarea"
                value={sendEncuesta[dataContent.Comentario0]}
                name={`Comentario${dataContent.Key}`}
              />
            </Label>
          </FormGroup>
        ) : null}
      </section>
    </>
  );
};

export default RadioQuestion;
