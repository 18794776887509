import React, { useContext } from 'react';
import ListItemsContex from '../context/ListItemsContex';
import QuestionsContent from '../assets/json/modalContent.json';
import ModalAlert from '../components/modalalert';
import Loading from '../components/loading';
export const ShowModal = () => {
  const {
    showModal,
    showModalError,
    showModalErrorAuth,
    showModalErrorGeneric,
    showModalExist,
    showModalExistEnc,
    loadingBoss,
  }: any = useContext(ListItemsContex);
  return (
    <>
      {loadingBoss ? <Loading /> : null}
      {showModal ? (
        <ModalAlert modalContent={QuestionsContent.ModalSendEncuesta} />
      ) : null}
      {showModalError ? (
        <ModalAlert modalContent={QuestionsContent.ModalErrorEncuesta} />
      ) : null}
      {showModalErrorAuth ? (
        <ModalAlert modalContent={QuestionsContent.ModalErrorEncuestaAuth} />
      ) : null}
      {showModalErrorGeneric ? (
        <ModalAlert modalContent={QuestionsContent.ModalErrorEncuestaGeneric} />
      ) : null}
      {showModalExist ? (
        <ModalAlert modalContent={QuestionsContent.ModalExist} />
      ) : null}
      {showModalExistEnc ? (
        <ModalAlert modalContent={QuestionsContent.ModalExist} />
      ) : null}
    </>
  );
};
