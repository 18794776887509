export const getCachedToken = () => {
  return sessionStorage.getItem('encuesta_token_id');
}

export const getGetHttpOptions = () => {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getCachedToken()}`
    }
  }

  return options;
}