import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../../assets/sass/components/_modals.scss';

const ModalAlert = ({ modalContent }, { modalOpen }) => {
  console.log(modalOpen);
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  return (
    <div>
      {modalContent.map((object, key) => (
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-default"
          backdrop={object.backdrop}
          key={`${object.title}-${key}`}
        >
          <ModalHeader>
            <div></div>
            <em
              id={`em-${key}`}
              className="fal fa-times-circle"
              onClick={() => {
                sessionStorage.removeItem('encuesta_token_id');
                window.open('about:blank', '_self');
                window.close();
              }}
            />
          </ModalHeader>

          <>
            <ModalBody>
              <h3 className="title-modal">{object.title}</h3>
              <div>
                {object.success && (
                  <em className="fal fa-check-circle rotate-center success-alert" />
                )}

                {object.error && (
                  <em className="fal fa-times-circle heartbeat error-alert" />
                )}
                {object.info && (
                  <em className="fal fa-info-circle scale-up-center" />
                )}
                <p>{object.textBody}</p>

                {object.ContactAvailable && (
                  <p className="modal-contact">
                    Para más información comunícate con nosotros
                    <a
                      href="mailto:co-contacto@suramericana.com.co"
                      className="modal-link"
                    >
                      co-contacto@suramericana.com.co
                    </a>
                  </p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              {object.OkButtonAvailable && (
                <Button className="btn-generic" onClick={toggle}>
                  {object.OkButtonText}
                </Button>
              )}
              {object.CancelButtonAvailable && (
                <Button
                  id={`cancelItemBtn-${key}`}
                  className="btn-generic"
                  onClick={toggle}
                >
                  {object.CancelButtonText}
                </Button>
              )}
              {object.CloseButtonAvailable && (
                <Button
                  id={`removeItemBtn-${key}`}
                  className="btn-generic"
                  onClick={() => {
                    sessionStorage.removeItem('encuesta_token_id');
                    window.open('about:blank', '_self');
                    window.close();
                  }}
                >
                  {object.CloseButtonText}
                </Button>
              )}
            </ModalFooter>
          </>
        </Modal>
      ))}
    </div>
  );
};

export default ModalAlert;
