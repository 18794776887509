import { Label, Form, Col, FormGroup, Input } from 'reactstrap';
import ListItemsContex from '../../context/ListItemsContex';
import React, { useContext } from 'react';
const TableRadioQuestion = ({ tableContent }) => {
  const { sendEncuesta, handleChangeInput, fieldsReq } = useContext(
    ListItemsContex
  );

  const getAlert = () => {
    let keys = tableContent.PreguntasCalificacion_x003A_Key.map(
      (element) => element.LookupValue
    );
    let response = keys.some((key) => !sendEncuesta[key]);

    return response;
  };

  return (
    <section
      className={`section-default ${tableContent.Requerido ? 'required' : ''}`}
      key={`${tableContent.Key}-67${tableContent.id}`}
    >
      <Form>
        <Col xs="12" sm="12" className="separated">
          <div
            className="required"
            key={`${tableContent.Key}-55${tableContent.id}`}
          >
            <Label className="separated-title">{tableContent.Title}</Label>

            <div className="table__list">
              <FormGroup>
                <div className="table__list-column" key={`${tableContent.Key}`}>
                  <ul>
                    <li className="list__title">Item</li>
                    {tableContent.PreguntasCalificacion &&
                      tableContent.PreguntasCalificacion.length > 0 &&
                      tableContent.PreguntasCalificacion.map(
                        (answers, key) => (
                          <li
                            className={`container-li ${answers.LookupValue}`}
                            key={`${answers.LookupValue}--${key}`}
                          >
                            {answers.LookupValue}
                          </li>
                        )
                      )}
                    {tableContent.PreguntasCalificacion_x003A_Key &&
                      tableContent.PreguntasCalificacion_x003A_Key.length > 0 &&
                      tableContent.PreguntasCalificacion_x003A_Key.map(
                        (element) => element.LookupValue
                      ).some((anyKey) =>
                        Object.values(fieldsReq).includes(anyKey)
                      ) &&
                      getAlert() && (
                        <p className="alert_required">
                          <em className="fas fa-times-circle" />
                          Faltan campos por diligenciar
                        </p>
                      )}
                  </ul>
                </div>
                {tableContent.RespuestasCalificacion &&
                  tableContent.RespuestasCalificacion.length > 0 &&
                  tableContent.RespuestasCalificacion.map(
                    (answer, key) => (
                      <div
                        className="table__list-column"
                        key={`${key}-${answer.LookupId}`}
                      >
                        <ul>
                          <>
                            <li
                              className="container-li-option"
                              key={`${answer.LookupValue}-/-${key}`}
                            >
                              <li className="list__title">
                                {answer.LookupValue}
                              </li>
                            </li>
                            {tableContent.PreguntasCalificacion_x003A_Key &&
                              tableContent.PreguntasCalificacion_x003A_Key
                                .length > 0 &&
                              tableContent.PreguntasCalificacion_x003A_Key.map(
                                (answers, keyX) => (
                                  <li
                                    className={`${answers.LookupValue}`}
                                    key={`${answers.LookupId}--${keyX}`}
                                  >
                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          key={`input-radio--${key}`}
                                          id={`input-radio-${key}`}
                                          onChange={handleChangeInput}
                                          type="radio"
                                          value={answer.LookupValue}
                                          name={answers.LookupValue}
                                        />
                                        <div className="control" />
                                      </Label>
                                    </FormGroup>
                                  </li>
                                )
                              )}
                          </>
                        </ul>
                      </div>
                    )
                  )}
              </FormGroup>
            </div>
          </div>
        </Col>
      </Form>
      {tableContent.Comentario0 ? (
        <FormGroup check>
          <Label check className="separated-title">
            Comentarios
            <Input
              onChange={handleChangeInput}
              type="textarea"
              value={sendEncuesta[tableContent.Comentario0]}
              name={`Comentario${tableContent.Key}`}
            />
          </Label>
        </FormGroup>
      ) : null}
    </section>
  );
};

export default TableRadioQuestion;
