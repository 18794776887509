import { Row } from 'reactstrap';


const IntroductionText = () => {

  return (
    <>
      <Row>
        <h2 className="title-big tx-center">
          Compártenos tu experiencia en SURA
        </h2>
        <p className="pg-section tx-center">
          En SURA valoramos el tiempo que compartiste con nosotros, y consideramos de gran relevancia conocer tu experiencia, cómo te sentiste, qué aspectos quieres resaltar y cuáles necesitamos mejorar. Al responder las siguientes preguntas, conoceremos tu percepción y tendremos la oportunidad de avanzar en nuestras prácticas.
          De antemano te agradecemos tu disposición, apertura y honestidad al diligenciarlo:
        </p>
      </Row>
    </>
  );
}

export default IntroductionText;