import React from 'react';
import { useEffect, useState } from 'react';
import ListItemsService from '../services/ListItemsService';
import { format } from 'date-fns';
var jwt = require('jsonwebtoken');
export const UseListItems = () => {
  const [listItems, setListItems] = useState({ value: [] } as { value: any[] });
  const [dataValidate, setDataValidate] = useState([] as any);
  const [listSections, setLisSections] = useState({ value: [] } as {
    value: any[];
  });
  const [loadQuestions, setloadQuestions] = useState(true);
  const [showModalError, setShowModalError] = useState(false);
  const [loadingBoss, setLoadingBoss] = useState(false);
  const [showModalErrorGeneric, setShowModalErrorGeneric] = useState(false);
  const [showModalExist, setShowModalExist] = useState(false);
  const [showModalErrorAuth, setShowModalErrorAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoad, setShowLoad] = useState(true);
  const [loadSections, setloadSections] = useState(true);
  const [sendEncuesta, setSendEncuesta] = useState([] as any);
  const [valueStart, setValueStart] = useState(false);
  const [fieldsReq, setFieldsReq] = useState([] as any);
  const [disabledSend, setDisabledSend] = useState(true);
  const [errorLoad] = useState(false);
  const [errorLoadList] = useState(false);
  const [valueChecked, setValueChecked] = useState(false);
  const [showModalExistEnc, setShowModalExistEnc] = useState(false);
  const [preload, setPreload] = useState<any>({});
  const [reasons, setReasons] = useState<any>([]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenid = urlParams.get('encuesta_token_id');
    if (tokenid) {
      sessionStorage.setItem('encuesta_token_id', tokenid);
      window.history.pushState('', document.title, '/');
    }
  }, []);

  const getToken = async () => {
    const token = sessionStorage.getItem('encuesta_token_id');
    try {
      if (token) {
        var decoded = jwt.decode(token);
        if (Object.keys(decoded).length != 0) {
          return decoded;
        }
      } else {
        setShowModalError(true);
        setDisabledSend(true);
      }

      return null;
    } catch (error) {
      setShowModalError(true);
      setDisabledSend(true);
      return null;
    }
  };

  const init = async () => {
    const decoded = await getToken()
    if (decoded) {
      getListSections();
      await getListItems();
      const exist = await validExist(decoded);
      if (!exist) {
        const item = await ListItemsService.getEncuesta({
          id: decoded.id,
        });
        setDisabledSend(false);
        setPreload(item.data);
        setSendEncuesta(item.data);
      }
    }
  }

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    let object = { [name]: value };

    if (name === 'MotivosRenuncia') {
      let newReasons = [...reasons];
      if (newReasons.indexOf(value) >= 0) {
        newReasons.splice(newReasons.indexOf(value), 1);
      } else {
        newReasons.push(value);
      }
      setReasons(newReasons);
      object = { [name]: newReasons.join('; ') };
    }


    setSendEncuesta({ ...sendEncuesta, ...object });
    setValueStart(true);
    setValueChecked(false);
  };

  const sendAllEncuesta = async (e: any) => {
    let fieldsRequired = listItems.value
      .filter((item: any) => item.fields.Requerido)
      .reduce((result: string[], actual: any) => {
        if (
          actual.fields.TipoPregunta === 'SiNo' ||
          actual.fields.TipoPregunta === 'CalificacionPreguntas'
        ) {
          let newResult = result.concat(
            actual.fields.PreguntasCalificacion_x003A_Key.map(
              (elementY: any) => elementY.LookupValue
            )
          );
          return newResult;
        } else {
          return [...result, actual.fields.Key];
        }
      }, []);

    if (sendEncuesta.Destinatario === 'Proveedor') {
      fieldsRequired.push('AnalisisEntrevista')
    } else {
      fieldsRequired = fieldsRequired.filter((x: string) => x !== 'MotivosRenuncia')
    }


    const fieldsEncuesta = Object.keys(sendEncuesta);
    const encuestaValida = fieldsRequired.every((fieldReq: string) =>
      fieldsEncuesta.includes(fieldReq)
    );

    if (encuestaValida) {
      try {
        const data = { ...sendEncuesta };
        data['FechaDiligenciamiento'] = format(new Date(), 'dd/MM/yyyy');
        const result = await ListItemsService.sendEncuesta(data);
        if (result) {
          setShowModal(true);
          setDisabledSend(true);
          setLoadingBoss(true);
        } else {
          setShowModalError(true);
        }

        setDisabledSend(true);
      } catch (error) {
        setShowModalError(true);
        setDisabledSend(true);
      }
    } else {
      let camposFaltantes = fieldsRequired.filter(
        (fieldReq: string) => !fieldsEncuesta.includes(fieldReq)
      );

      var element = document.getElementsByClassName(camposFaltantes[0])[0];

      if (element) {
        element.scrollIntoView();
        element.scrollIntoView(false);
        element.scrollIntoView({ block: 'center' });
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }

      let stringFaltantes = '';
      camposFaltantes.forEach(
        (campoFaltante: string) => (stringFaltantes += ` ${campoFaltante}`)
      );

      setFieldsReq({ ...camposFaltantes });
    }
  };

  const getListItems = async () => {
    try {
      const data = await ListItemsService.getListItems();
      setListItems(data);

      if (data && data.length && !loadingBoss) setDisabledSend(false);
      setloadQuestions(false);
    } catch (error) {
      setShowModalErrorGeneric(true);
      setDisabledSend(true);
    }
  };

  const getListSections = async () => {
    try {
      const data = await ListItemsService.getListSections();
      setLisSections(data);
      if (data && data.value.length) setloadSections(false);
    } catch (error) {
      setDisabledSend(true);
      setShowModalErrorGeneric(true);
    }
  };

  const validExist = async (decoded: any) => {
    try {
      if (decoded.id) {
        const data = await ListItemsService.validExist({
          id: decoded.id,
        });
        setShowModalExist(data);
        setLoadingBoss(data);
        setDisabledSend(true);

        return data;
      }
    } catch (error) {
      setDisabledSend(true);
      setShowModalError(true);
    }
  };

  useEffect(() => {
    init()
  }, []);

  return {
    listItems,
    dataValidate,
    setDataValidate,
    setListItems,
    listSections,
    setLisSections,
    loadQuestions,
    setloadQuestions,
    loadSections,
    setloadSections,
    sendEncuesta,
    setSendEncuesta,
    handleChangeInput,
    sendAllEncuesta,
    preload,
    setPreload,
    showModalExist,
    setShowModalExist,
    setValueStart,
    valueStart,
    disabledSend,
    setDisabledSend,
    showModal,
    showModalError,
    setShowModalError,
    setShowModal,
    showModalErrorAuth,
    setShowModalErrorAuth,
    showModalErrorGeneric,
    setShowModalErrorGeneric,
    fieldsReq,
    errorLoad,
    setFieldsReq,
    showLoad,
    setShowLoad,
    errorLoadList,
    valueChecked,
    setValueChecked,
    showModalExistEnc,
    setShowModalExistEnc,
    loadingBoss,
    setLoadingBoss,
    validExist,
    getToken,
    getListSections,
    getListItems,
  };
};
