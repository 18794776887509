import 'rc-rate/assets/index.css';
import { Label, FormGroup, Form, Input } from 'reactstrap';
import React, { useContext } from 'react';
import ListItemsContex from '../../context/ListItemsContex';
const FreeComment = ({ dataComment }) => {
  const { fieldsReq, sendEncuesta, handleChangeInput } = useContext(
    ListItemsContex
  );
  return (
    <>
      <section
        className={`section-default separate ${dataComment.Requerido
          ? "required"
          : ""
          }`}
        key={`${dataComment.Key}-67`}
      >
        <Form>
          <FormGroup check>
            <Label
              check
              className={`separated-title required ${dataComment.Key}`}
            >
              <Label>{dataComment.Title}</Label>
              <Input
                className={dataComment.key}
                onChange={handleChangeInput}
                type="textarea"
                value={sendEncuesta[dataComment.Key]}
                name={dataComment.Key}
              />
            </Label>
          </FormGroup>
        </Form>
        {Object.values(fieldsReq).map((field, key) =>
          field === dataComment.Key && !sendEncuesta[dataComment.Key] ? (
            <p className="alert_required">
              <em className="fas fa-times-circle" />
              Campo obligatorio
            </p>
          ) : (
            ''
          )
        )}
      </section>
    </>
  );
};

export default FreeComment;
