
type TitleProps = {
  title: string;
  id: any;
};
const TitleSections = ({ title, id }: TitleProps) => {
  return (
    <>
      <h3 id={id} className="title-section tx-center ppal">
        {title}
      </h3>
    </>
  );
};

export default TitleSections;
