import RateQuestion from '../components/ratequestion/index';
import TableRateQuestion from '../components/tableratequestion';
import TableRadioQuestion from '../components/tableradioquestion';
import RadioQuestion from '../components/radioquestion';
import FreeComment from '../components/freecomment';
import TableCheckQuestion from '../components/tablecheckquestion';

const TypeQuestion = ({ dataContent }: any) => {
  const renderTypeQuestions: any = (content: any) => {
    let type = content && content.TipoPregunta;
    let component;
    switch (type) {
      case 'Calificacion':
        component = <RateQuestion dataContent={content} />;
        break;
      case 'SiNo':
        component = <TableRadioQuestion tableContent={content} />;
        break;
      case 'CalificacionPreguntas':
        component = <TableRateQuestion dataContent={content} />;
        break;
      case 'UnicaRespuesta':
        component = <RadioQuestion dataContent={content} />;
        break;
      case 'TextoLibre':
        component = <FreeComment dataComment={content} />;
        break;
      case 'SeleccionMultipleDosNiveles':
        component = <TableCheckQuestion tableContent={content} />;
        break;
      default:
        return null;
    }

    return component;
  };

  return renderTypeQuestions(dataContent);
};

export default TypeQuestion;
