import React, { useContext } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import ModalAlert from '../components/modalalert';
import ListItemsContex from '../context/ListItemsContex';
import '../components/footer/index.scss';
import Loading from '../components/loading';
import QuestionsContent from '../assets/json/modalContent.json';
export const AllSections = () => {
  const {
    listSections,
    loadQuestions,
    sendAllEncuesta,
    disabledSend,
    errorLoad,
  } = useContext(ListItemsContex);

  return (
    <>
      <Nav tabs className="tab-steps">
        {loadQuestions ? (
          <Loading />
        ) : errorLoad ? (
          <ModalAlert
            modalContent={QuestionsContent.ModalErrorEncuestaGeneric}
          />
        ) : (
          listSections.value.map((secciones) => (
            <NavItem>
              <NavLink href={`#section${secciones.fields.id}`}>
                <div className="icon-list">
                  <em className={`${secciones.fields.Icon}`} />
                </div>
                {secciones.fields.Title}
              </NavLink>
            </NavItem>
          ))
        )}
        {!disabledSend && (
          <NavItem>
            <NavLink
              disabled={disabledSend}
              className="save"
              onClick={sendAllEncuesta}
            >
              <em className="fal fa-paper-plane" />
              Enviar
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </>
  );
};
