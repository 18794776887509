import Logo from '../../assets/images/logo.png';
import './index.scss';

const Header = () => {

  return (
    <header className="header-ppal">
      <img src={Logo} className="separated-img" width="100"/>
      <h2 className="h2-title">
        Entrevista <strong>de retiro</strong>
      </h2>
    </header>
  );
}

export default Header;